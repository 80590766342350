<template>
  <div>
  <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>

body {
  background: black;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
</style>
